<template>
  <div class="content pageInit">
    <h1 class="pageTitle textColor">Presentation Builder</h1>
    <!--下拉框-->
    <div class="selectContainer">
      <el-select popper-class="x-select" v-model="item.value" :placeholder="item.placeholder"
                 v-for="item in selectData">
        <el-option
            v-for="jtem in item.options"
            :key="jtem.value"
            :label="jtem.label"
            :value="jtem.value">
        </el-option>
      </el-select>
    </div>
    <!--按钮-->
    <p style="margin: 250px 0px 30px 0px">
      <el-button class="x-btn" @click="getPresentationData">Submit</el-button>
    </p>
    <hr style="margin-bottom:30px;display: block">
    <h1 class="pageSubTitle">CHOOSE YOUR PRESENTATION</h1>
    <div class="imgContainer">
      <imgBoxFlex
          v-for="item in contents"
          style="cursor: pointer;width:20%"
          :data="item"
      ></imgBoxFlex>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, toRefs, onBeforeMount, onMounted} from 'vue'
import imgBoxFlex from "@/components/page/imgBoxFlex.vue";
import axios from '@/api/http.js';
import {ElLoading} from 'element-plus';

export default defineComponent({
  name: 'Presentation',
  components: {
    imgBoxFlex,
    ElLoading
  },
  setup() {
    const data = reactive({
      selectData: [
        // {
        //   options: [
        //     {
        //       value: 'Topic Sed Diam 1',
        //       label: 'Topic Sed Diam 1'
        //     }, {
        //       value: 'Topic Consectertuer Elit 2',
        //       label: 'Topic Consectertuer Elit 2'
        //     }, {
        //       value: 'Topic Nibh Euismod 3',
        //       label: 'Topic Nibh Euismod 3'
        //     }, {
        //       value: 'Topic Ut Laoreet 4',
        //       label: 'Topic Ut Laoreet 4'
        //     }], value: '', placeholder: "Choose Your Audience"
        // },
      ],
      contents: [
        // {
        //   img: require("../../assets/imgs/hulk.jpg"),
        //   id: 1,
        //   name: "VMWARE TANZU",
        //   title: "2021 WMKO Intrinsic Security Solutions",
        //   description:
        //       " Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi natus dolores aspernatur optio ad modi iure quisquam",
        // },
        // {
        //   img: require("../../assets/imgs/hulk.jpg"),
        //   id: 1,
        //   name: "VMWARE TANZU",
        //   title: "VMWARE End-to-end Security Story",
        //   description:
        //       " Lorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit Eligendi natus dolores aspernatur optio ad modi iure quisquamLorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit Eligendi natus dolores aspernatur optio ad modi iure quisquamLorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit Eligendi natus dolores aspernatur optio ad modi iure quisquamLorem ipsum dolor sit amet consectetur adipisicing elit.Lorem ipsum dolor sit amet consectetur adipisicing elitLorem ipsum dolor sit amet consectetur adipisicing elit Eligendi natus dolores aspernatur optio ad modi iure quisquam",
        // },
        // {
        //   img: require("../../assets/imgs/hulk.jpg"),
        //   id: 1,
        //   name: "VMWARE TANZU",
        //   title: "2021 WMKO Intrinsic Security Solutions",
        //   description:
        //       " Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi natus dolores aspernatur optio ad modi iure quisquam",
        // },
        // {
        //   img: require("../../assets/imgs/hulk.jpg"),
        //   id: 1,
        //   name: "VMWARE TANZU",
        //   title: "2021 WMKO Intrinsic Security Solutions",
        //   description:
        //       " Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi natus dolores aspernatur optio ad modi iure quisquam",
        // },
      ],
    })
    const formatData = (params) => {
      console.log("==== params=====", params)
      let arr = [];
      params.map(item => {
        let obj = {};
        obj["options"] = item.options;
        obj["value"] = "";
        obj["placeholder"] = item.placeholder;
        obj["type"] = item.type;
        arr.push(obj);
      })
      data.selectData = arr;
    }
    const getSelectData = () => {
      axios.request({
        method: 'PATCH',
        url: '/virtual/presentation',
        data: {
          meetingId: "mg9lr5rzd62to2hcidef11id"
        }
      }).then(res => {
        console.log('getSelectData:', res);
        // data.selectData = res.data.data;
        formatData(res.data.data);
      }).catch(err => {
        console.log(err);
      })
    }
    getSelectData();
    const getPresentationData = () => {
      let topic = "";
      let vertical = "";
      let solutions = "";
      data.selectData.map(item => {
        if (item.type == 'topic') {
          topic = item.value;
        } else if (item.type == 'vertical') {
          vertical = item.value;
        } else if (item.type == 'solutions') {
          solutions = item.value;
        }
      })
     const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'PATCH',
        url: '/virtual/presentationContent',
        data: {
          meetingId: "a7yyxczuty0qpjo2393gmyad",
          topic: topic,
          vertical: vertical,
          solutions: solutions
        }
      }).then(res => {
        console.log('res:', res);
        loading.close();
        data.contents = res.data.data;
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    onBeforeMount(() => {
    })
    onMounted(() => {
    })
    return {
      ...toRefs(data),
      getSelectData,
      getPresentationData,
      formatData
    }
  },
})

</script>
<style scoped lang='scss'>
.content {
  .selectContainer {
    display: inline-block;
    border: 1px solid rgb(137, 192, 228);
    margin: 0px 0px 30px 0;
  }

  .imgContainer {
    display: flex;
    flex-wrap: wrap;

    div {
      margin-right: 30px;
      margin-bottom: 30px;
    }
  }
}
</style>
<!--自定义下拉框的样式-->
<style lang="scss">
.content {
  .selectContainer {
    .el-input__inner {
      border-radius: 0;
      border-color: rgb(137, 192, 228);
      border: none;
      white-space: nowrap;
      display: table;

      &:hover {
        background: rgb(69, 77, 88);
        color: #fff;

        + .el-input__suffix i {
          color: rgb(70, 245, 6);
        }
      }
    }

    .el-select {
      width: 250px;
    }

    .el-select .el-input .el-select__caret {
      color: rgb(6, 150, 245);
    }
  }
}

.x-select {
  margin-top: -13px;

  .el-select-dropdown__list {
    border: 1px solid #89c0e4 !important;

    li:nth-child(odd) {
      background: #fff;
    }

    li:nth-child(odd) {
      background: #f1f9fc;
    }

    li {
      color: gray;
    }

    .el-select-dropdown__item.selected {
      color: rgb(6, 150, 245);
    }

    li:hover {
      background: rgb(6, 150, 245);
      color: #fff !important;
    }
  }

  .el-select-dropdown__list {
    margin: 0;
  }

  .el-menu--popup {
    box-shadow: none;
  }
}

</style>
