import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/imgs/iconClose.jpg'


const _withScopeId = n => (_pushScopeId("data-v-202c784e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "titleTop textColor"
}
const _hoisted_2 = {
  key: 1,
  class: "title textColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["imgBoxFlex", {'showShadow':$props.showShadow}]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickImg && _ctx.clickImg(...args)))
    }, [
      ($props.titleOnTop)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($props.data.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "imgContainer",
        style: _normalizeStyle(`background: url(${$props.data.img})center center / cover no-repeat;padding-bottom:calc(${$props.paddingBottom}%)`)
      }, [
        _withDirectives(_createElementVNode("p", { class: "titleNoShadow textColor" }, _toDisplayString($props.data.name), 513), [
          [_vShow, $props.inside&!$props.titleOnTop&&!$props.haveShadow]
        ])
      ], 4),
      _withDirectives(_createElementVNode("p", { class: "centerText" }, _toDisplayString($props.data.name), 513), [
        [_vShow, $props.inside&$props.showName]
      ]),
      _withDirectives(_createElementVNode("img", {
        class: "closeIcon",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.deleteItem($props.data.id))),
        src: _imports_0,
        alt: ""
      }, null, 512), [
        [_vShow, $props.showClose]
      ]),
      ($props.inside&!$props.titleOnTop&&$props.haveShadow)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($props.data.name), 1))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("p", { class: "bottomText textColor" }, _toDisplayString($props.data.description), 513), [
        [_vShow, $props.inside&$props.showBottomText]
      ])
    ], 2),
    _withDirectives(_createElementVNode("p", { class: "bottomText textColor" }, _toDisplayString($props.data.name), 513), [
      [_vShow, !$props.inside&$props.showBottomText]
    ]),
    _withDirectives(_createElementVNode("p", { class: "bottomText textColor" }, _toDisplayString($props.data.description), 513), [
      [_vShow, !$props.inside&$props.showBottomText]
    ])
  ], 64))
}